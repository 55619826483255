
.CartHeader #logo_size {
    margin-top: 15px;
    margin-left: 90px;
    width: 120px;
    height: 26px;
}

.CartHeader #cart_logo {
    width: 20px;
    height: 20px;
    margin-top: 10px;
    padding-left: 180px;
}

.CartHeader  #header {
    height: 70px;
    /* border: 1px solid black; */
    /* background-color: yellow; */
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    padding: 20px 10px -10px 10px;
    margin-left: 80px;
}
.CartHeader  #notification{
    display: grid;
    grid-template-columns: 45% 10%;
    padding-left: 25%;
    margin-top: 3%;
}
.CartHeader  #img {
    margin-top: 10px;
    width: 80px;
    height: 80px;
}
.CartHeader  #description {
    margin-top: 1px;
    margin-left: 70px;
    font-size:13.2px;
}

#description_color {
    color: rgb(161, 161, 161);
}
#notification_img{
    width: 25px;
    height: 25px;
}
.CartHeader  #name_in_notify{
    margin: 15% 1% 0 0;
}

#cart_color {
    /* color: #e96a19; */
}

.CartHeader  hr {
    background-color: rgb(206, 201, 201);
    height: 0.001px;
    border: 0.01px solid rgb(206, 201, 201);
    color: rgb(206, 201, 201);
}
.CartHeader  #capital{
    font-weight: 600;
    text-transform: uppercase;
}