.product {
  color: black;
  text-align: center;
  height: 100px;
 
}

.sortingdiv {
  width: 300px;

  margin: 10px;
  margin-top: 0px;
  margin-left: 60px;
  height: 700px;
}

.productdiv {
  width: 1000px;

  margin: 15px;
  margin-top: 0px;
}
.flex {
  display: flex;
}
.productdiv #link, #btn{
  text-decoration: none;
}
#spandiscount {
  text-decoration: line-through;
  color: grey;
  font-weight: normal;
  font-size: 13px;
  margin-left: 5px;
}

#spanbutton {
  margin-left: 100px;
}
#productsMain{
  text-decoration: none;
  color : black
}

.listitem > .discount {
  margin-bottom: 5px;
  color: green;
  font-weight: bold;
  font-size: 14px;
}

.listitem > .price {
  margin-bottom: 5px;
  color: orange;
  font-weight: bold;
  font-size: 18px;
}