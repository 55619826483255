.cart #cartpage {
  margin: 10px 50px;
}
.cart #cart {
  width: 800px;
}
.cart #summary {
  width: 380px;
  margin-left: 20px;
}

.cart #maindiv {
  display: flex;
  margin-top: 10px;
}

.cart .border {
  border: 1px solid #e7e7e7;
  margin: 10px;
}

.cart #login {
  margin: 3px 0px;
  font-weight: normal;
}
.cart #logindown {
  color: grey;
  font-size: 14px;
  font-weight: lighter;
}
#couponicon {
  margin-left: 170px;
  font-size: 20px;
}

.cart #retaildiscount {
  color: #588209;
  font-size: 0.8rem;
}
.cart #deletebtn{
  height: 40px;
  width: 40px;
}

#cartvalue {
  font-size: 0.8rem;
}

#retailprice {
  margin-left: 180px;
  color: #588209;
  font-size: 0.8rem;
}

.cart #cartprice {
  margin-left: 220px;
  font-size: 0.8rem;
}

.cart #inclusive {
  font-size: 13px;
  margin-left: 0px;
  text-align: end;
}

.cart #placeorderbutton {
  background-color: coral;
  width: 100%;
  height: 40px;
}
.cart #label {
  font-size: 15px;
  color: grey;
  margin-top: 10px;
}

.cart #checkbox {
  width: 20px;
  height: 20px;
}
.cart .checkboxdiv {
  margin: 6px;
}
.cart #linkBtn{
  text-decoration: none;
}

#pincode {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  border: 1px solid #e7e7e7;
}

#pincode :nth-child(1) {
  color: #969696;
  padding: 10px;
}

.cart #spanbutton2 {
  color: wheat;
  background-color: black;
  border: 1px solid black;
}

.cart #productscart {
  border: 1px solid #d1d1d1;
  display: flex;
}

.cart #productscart :nth-child(1) {
  padding: 10px;
}
.cart #productscart img{
  width: 150px;
}

.cart  #innerdata {
  width: 350px;
}

.cart #innerdata :nth-child(1) {
  font-size: 16px;
}

.cart #innerdata :nth-child(2) {
  font-size: 14px;
  color: coral;
  padding-left: 10px;
}

.cart #innerdata :nth-child(3) {
  font-size: 14px;
  color: grey;
  padding-left: 10px;
}

.cart #innerdata :nth-child(4) {
  font-size: 14px;
  padding-left: 10px;
}

.cart #qtyform {
  width: 100px;
}
