.loginPage{
    display: flex;
    justify-content: center;
    background-color: white;
    /* width: 500px; */
    z-index: 100;
    margin: auto;
}
.loginPage #cancel{
    background-color: white;
    border: none;
    width: 10px;
    height: 10px;
    font-size: 20px;
    position: absolute;
    text-align: right;
    top:0;
    margin-left: 280px;
}
.loginPage .form{
    margin: 20px 40px;
}
.loginPage .form form{
    display: flex;
    flex-direction: column;
    width: 300px;
    
}
.loginPage .form form input{
    margin: 10px 0;
    border: none;
    border-bottom: 2px solid gray;
    font-size: 15px;
    background-color: white;
    padding: 6px;
}
.loginPage .form > p{
    font-size: 12px;
}
.loginPage #signupTxt{
    text-align: center;
    margin-top: 50px;
}
.loginPage #loginBtn{
    background-color: white;
}
.loginPage .form img{
    width : 30px
}
.loginPage #loginPoster{
    background-image: url("https://ii1.pepperfry.com/images/new_login_modal_bg_2020.jpg");
    height: 450px;
    width: 250px;
    
}
.loginPage #loginPoster p{
    font-size: 15px;
    margin-left: 30px;
}
.loginPage #loginPoster h2{
    font-size: 15px;
    font-weight: 500;
    margin-left: 30px;
    color: #f16521;
}