#order_summary > img {
    width: 5%;
    height: 5%;
}
.checkout {
    width: 95%;
    margin: auto;
    /* display: grid;
    grid-template-rows: 5% 1% 80% 10%; */
}
.checkout #card_d{
    height: 100px;
    display: flex;
    flex-direction: row;
    margin: 2%;
    border: 1px solid black;
    background-color: white;
    
}
.checkout #right_d{
    width: 90px;
    height: 90px;
    font-size: 13px;
    margin: 2%;
    padding-top: 5%;
}
#left_d{
    margin: 2%;

}
#price_d{
    display: flex;
    flex-direction: row;
}
#logo_size {
    width: 140px;
    height: 30px;
}


.checkout #middle {
    width: 90%;
    /* height: 60%; */
    display: grid;
    margin: 1% 1% 1% 1%;
    margin: auto;
    padding-top: 20px;
    grid-template-columns: 60% 30%;
    /* background-color: yellow; */
    justify-content: center;
    adding : 20px;
}
#mid_left {
    
    display: grid;
    grid-template-rows: 5% 70%;
    overflow: scroll;
}

.checkout #mid_left::-webkit-scrollbar {
    background: transparent;
}

.checkout #mid_right {
    height: 200px;
    /* background-color: magenta; */
    margin: 0 10px 0 10px;
    border: 1px solid rgb(206, 201, 201);
}
/* #notification_img{
    width: 25px;
    height: 25px;
} */

.checkout #mid_left1{
    width: 95%;
    background: #f5f5f5;
    padding: 5px 0 -2px 5px;
     display: grid; 
    grid-template-rows: 5% 30%; 
    /* overflow:scroll; */
}
.checkout #order_summary{
    height: 100%;
    margin: 3% 2% 2% 2%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    padding: 1px;
}
.checkout #question_mark, #question_mark1{
    
    width: 25px;
    height: 25px;
    padding-left: 60%;
    margin: 1% 0 1% 20%;
}
#question_mark1{
    margin: 1% 0 1% 20%;
}
#order{
     height: 17%;
    margin: 1% 1% 1% 1%;
    display: none;
    overflow: scroll;

}
.price_color{
    margin-top: 10%;
    width: 5%;
    color: #e96a19;
    font-size: 11px;
}

#mid_left1_inside {
    /* height: 50px; */
    height: 50px;
    border: 1px solid #e7e7e7;
    background: #f5f5f5;
    padding: 5px 0 5px 0;
    margin: 0 0 2px 0;
    display: grid;
    grid-template-columns: 80% 10% 3%;
    grid-gap: 5px;
}

#mid_left2 {
    /* height: 50px; */
    width: 95%;
    margin-top: 0.5%;
    padding: 0 0 10px 0;
    /* background-color:rgb(205, 50, 153); */
}

#mid_left3 {
    /* height: 50px; */
    width: 100%;
    background-color: #f5f5f5;
    font-size:16px;
    padding: 10px 0 0px 15px;
    border: 1px solid rgb(231, 231, 231);
    margin: 0 0 0px 0;
    display: grid;
    grid-template-columns: 67% 27% 4%;
}
.mid_left12{
    margin: 1% 0 0 4%;
    border: none;
}
#ship{
    margin-left: 1%;
}
#mid_left21 {
    height: 5%;
    border: 1px solid #e7e7e7;
    background: #f5f5f5;
    padding: 5px 0 5px 0;
    margin: 0 0 2px 0;
    display: grid;
    grid-template-columns: 70% 25% 3%;

}

#mid_left22 {
    border: 1px solid rgb(231, 231, 231);
}


#mid_left11 {
    font-size: 14.5px;
    margin: 7px 0 0 0;
    border: none;
    color: rgb(150, 148, 148);
}

#showitems {
    width: 0;
    font-size: 30px;
    margin:0 0 50px 0;
    color: rgb(150, 148, 148);
    cursor: pointer;
    background: #f5f5f5;
}

#dash_style {
    margin: 10% 0 7px -10px;
    font-size: 25px;
    color: rgb(150, 148, 148);
    cursor: pointer;
}

#mid_left221 {
    font-size: 14.5px;
    margin: 10px 0 0 0;
    color: rgb(150, 148, 148);
    line-height: 15px;
}

#PaymentMethod {
    vertical-align: top;
    margin-top: -3px;
    font-size: 30px;
    color: rgb(150, 148, 148);
    cursor: pointer;
}

#mid_left32 {
    font-size: 14.5px;
    margin: 5px 0 0 0;
    color: rgb(182, 178, 178);
    line-height: 15px;
}

#mid_left31 {
    margin: 5px 0 0 0;
    color: rgb(150, 148, 148);
}

form {
    width: 70%;
    font-size: 20px;
    margin-top: 10px;
    padding-left: 2%
}

.label1 {
    width: 40px;
    font-size: 14.8px;
    padding-right: 130px;
    padding-top: 5px;
    color: rgb(150, 150, 150);
}

.label2 {
    width: 180px;
    font-size: 14.8px;
    padding-right: 80px;
    padding-top: 5px;
    color: rgb(150, 150, 150);
}

.label3 {
    width: 5px;
    font-size: 14.8px;
    padding-right: 0px;
    padding-top: 5px;
    color: rgb(150, 150, 150);
}

.label4 {
    width: 50px;
    font-size: 14.8px;
    padding-right: 135px;
    padding-top: 5px;
    color: rgb(175, 151, 151);
}
.side{
    display: grid;
    grid-template-columns: 26% 57%;
}
.side1{
    display: grid;
    grid-template-columns: 26% 30%;
}
input{
    color: #121212;
    font-size: 13px;
}

#city,
#city1,
#state1,
#name,
#address,
#address1,
#pincode1,
#pincode,
#moblie,
#moblie1,
#name1,
option {
    width: 500px;
    height: 32px;
    padding: 1%;
    padding-left: 10px;
    font-size: 14.5px;
    color: #4a4a4a;
    border-radius: 5px;
    border: 1px solid rgb(185, 185, 185);
}
#city,
#city1,
#state1,
option{
    margin-left: 2%;
}
#address,
#address1,
#pincode1,
#pincode,
#city,
#city1{
    margin-left: 5%;
}
#name,
#moblie{
    height: 35px;
    width: 495px;
   
}
#name1,
#moblie1{
    height: 35px;
    width: 498px;
   
}

/* #order{
     display: none; 
} */
#mid_left2221 {
    padding-left: 90px;
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 1px;
}
label{
    margin-left: 1%;
}
#country {
    width: 56%;
    padding: 5px;
    margin-left: 205px;
    border: 1px solid rgb(185, 185, 185);
    font-size: 14px;
    background-color: #f5f5f5;
}


#state {
    width: 200px;
    height: 45px;
    padding: 10px;
    margin-left: 32%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid rgb(185, 185, 185);;
}
#city,#city1, #state1 {
    height: 20px;
    width: 210px;
    padding: 10px;
    margin: 0 20% 0 22%;
    border-radius: 5px;
    border: 1px solid rgb(185, 185, 185);
}
#city{
    margin-left: 25%;
}
#state1,#state{
     width: 225px; 
    margin-left: 65%;
}
#state1{
    width: 202px;
}
#country1,#country{
    width: 97%;
    margin-left: 220px;
    padding: 10px;
    margin-left: 28%;
    border: 1px solid rgb(185, 185, 185);
    border-radius: 5px;
}
#country{
    width: 92%;
}
#country1{
    margin-left: 142px;
}
#address1,#address,#pincode,#pincode1{
    width: 495px;
}
#mid_left2222 {
    padding-left: 140px;
    display: grid;
    font-size: 14px;
    grid-template-columns: 8% 80%;
    grid-gap: 1px;
}

#mid_left2223 {
    padding-left: 200px;
    display: grid;
    font-size: 14px;
    grid-template-columns: 5% 80%;
    grid-gap: 1px;
}
#pincode1,#address1{
    width: 498px;
}
.checkout button {
    margin-left: 160px;
    width: 200px;
    height: 40px;
    border:  none;
    color: white;
    background-color: #f16521;

}

#city_part {
    display: grid;
    grid-template-rows: 50% 50%;
}

.material-icons-outlined{ /* plus icon button*/
    color: #969696;            
    font-size: 34px;
    cursor: pointer;
}

#address,#address1{
    height: 60px;
    border: 1px solid rgb(185, 185, 185);
    /* padding: 10px 10px 10px 10px; */
}
/* .checkbox{
    width: 20px;
    height: 20px;
    background-color: coral;
    color: white;
} */
.below_checkbox{
    font-size: 13.5px;
    width: 400px;
    margin-top: 5px;
}
#capital{
    text-transform: uppercase;
}

input[type="checkbox"]{
    appearance: none; 
    -webkit-appearance: none; 
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid black;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

}
input[type="checkbox"]:after{
    font-family: "Font Awesome 5 Free";
    font-weight: 800;
    content: "\f00c";
    font-size: 14px;
    color:azure;
    display:none;
    
}
input[type="checkbox"]:checked {
    border: none;
    background-color: #f16521;
}
input[type="checkbox"]:checked:after{
    display: block;
}
#name_in_notify{
    margin: 10% 1% 0 0;
}
#right_checkbox{
    display: grid;
    grid-template-columns: 10% 80%
}
.right_below1{
    margin: 10px;
    padding-left: 2%;
    font-size: 10px;
    color: #4a4a4a;
}
.right_below2{
    margin: 10px;
    padding-left: 0%;
    font-size: 11px;
    font-weight: 500;
    color:rgb(185, 185, 185);
}
#right_last{
    font-size: 12px;
    padding-top: 2%;
    color: #969696;
}
a{
    color: rgb(66, 66, 66);
}
#change_billing_color{
    font-size: 14.5px;
    color: #f16521;
    margin: 1% 1% 2% 1%;
    
}
#mid_right{
    padding: 5%;
    font-family: 'Fira Sans', sans-serif;
}
.right_in{
    display:grid;
    padding: 1.5%;
    font-size: 12px;
    color: #4a4a4a;
    grid-template-columns: 70% 30%;
}
.right_in1{
    text-align: right;
    font-size: 12px;
}
#right_in11{
    text-align: right;
    font-size: 12px;
}
#free_color{
    color: #f16521;
}
#retail{
    color: rgb(21, 128, 21);
}
#tax{
    font-size: 11px;
    color:rgb(185, 185, 185);
}
#total_font{
    font-size: 15px;
    /* font-weight: bold; */
}
#total_value{
    font-size: 15px;
}
#more{
    padding:1% 2% 0 0;
    font-size: 20px;
}
.num1{
    font-size: 14.5px;
    display: grid;
    grid-template-columns: 29% 30% 83%;
}
.num11{
    font-size: 14.5px;
    display: grid;
    grid-template-columns: 27.5% 30% 83%;
}

.nme{
    font-size: 14.5px;
    display: grid;
    grid-template-columns: 29% 60%;

}
.nme1{
    font-size: 14.5px;
    display: grid;
    grid-template-columns: 27.5% 60%;

}
#shipping
{
    font-size: 14.5px;
    margin-left: 3%;
}
.label1,
.label2{
    padding-top: 3px;
    padding-left: 5px;
}

.Spacing_between{
    padding: 6px;
}