#header{
    width:90%;
    margin:auto; 
 }
 #head1{
     height:80px;
     display:flex;
     background-color: white;
     /* position:fixed; */
     /* width:90%; */
     margin: auto 5%;
     z-index: 999;
     top:0;
 }
 #head1 img{
     width:15%;
 }
 #search{
     display:flex;
     /* border:1px solid grey; */
     margin: 18px 35px;
     width:35%;
     border-radius:5px;
     background-color: rgb(241, 235, 235);
   
 }
 #search input{
     width:80%;
    background-color: rgb(241, 235, 235);
     border-color: transparent;
 }
 #search img{
     width: 40px;
     padding:10px;
 }
 #options{
 margin:10px 2px;
 display:flex;
 
 }
 .items{
    width:50px;
     margin:5px;
     justify-content: center;
 align-items: center;
    
 }
 #options .items img{
     width: 25px;
     height:25px;
     margin: 15px;
 } 
 .items p{
     color:rgb(0, 0, 0);
    font-size: 13px;
     margin-top:-5px;
     margin-left:5px;
 }
 .last{
     margin-left: 5%;
     text-align: center;
 }
 last p{
     width: 100px;
 }
 .pin{
     margin-top: 2%;
 }
 .pin:hover{
 border-bottom: 3px solid orangered; 
 color:rgb(179, 48, 0);
 }
 #img11{
     height: 45%;
     width: auto;
   margin-top: 1.3%;
 }
 #img11 > img
 {
     height: 100%;
     width: 100%;
 }
 .nam{
    padding: 15px;
    color: black;
    font-size:16px;
    /* margin-top: -4%; */
    text-decoration: none; 
 }
 .img12
 {
     display: flex;
     margin-left: 40px;
     margin-top: 20px;
 }
 
 .nam:hover{
 border-bottom: 3px solid orangered; 
 color:rgb(179, 48, 0);
 }

 #first{
     /* align-self: left; */
     margin-left: -6%;
 }

 *{
     box-sizing: border-box;
     font-size: 15px;
 }
 nav{
     /* position:fixed; */
     width:100%;
     z-index:999;
      margin-top: -20px;
 }
 nav .wrapper{
     /* background-color:rgb(241, 237, 237); */
     /* max-width:85%; */
     padding:0 30px;
     margin:auto;
     margin-left:-10px;
     margin-top:6px;
     display:grid;
     grid-template-columns: 85% 10%;
     /* grid-gap: 5%; */
     align-items:center;
     box-shadow: border;
 }
 .wrapper .nav-links{
      display:flex;
      margin:-5px;
 }
 .nav-links li{
     list-style: none;
     padding: 18px 18px;
 }
 .nav-links li a{
     color:rgb(3, 3, 3);
     text-decoration: none; 
     font-size: 17px;
 }
 .nav-links li a:hover{
 border-bottom: 3px solid orangered; 
 color:rgb(179, 48, 0);
 }

 .nav-links li:hover .mega-box{
 top:115px;   
 opacity:1;
 visibility: visible;
 transition:all 0.4s ease;
 
 }
 .mega-box{
 position:absolute;
 top: 50px;
 background:rgb(240, 231, 231);
 width:90%;
 left:0;
 margin-left:70px;
 opacity: 0;
 visibility:hidden;
 transition:all 0.3s ease;
 z-index: 10;
 }
 .mega-box .content{
 background:rgb(255, 250, 250);
 padding:15px 20px;
 display:flex; 
 width:90%; 
 justify-content: space-between;
 }
 .content .rowone header{
 color:black;
 font-size:20px;
 font-weight:bold;
 margin-left:20px;
 
 }
 /* .content .rowone .mega-links{
 border-left:1px solid black;
 } */
 .rowone .mega-links li{
 padding:10px 10px;
 }
 .rowone .mega-links li a{
 
 display:block;
 }
 .one{
     margin-left: 5px;
     
 }
 .brand{
     text-align: center;
     font-size:20px;

 }
 .a{
    width: 200px;

 }
 .b{
    width: 160px;

 }
 .c{
    width: 150px;

 }
 .d{
     margin-left: 15px;
 }
 
 .d1{
    margin-right: 200px;
 }
.HeadMain #link{
    text-decoration: none;
}
 