#Cartfooter .footer_img{
    width: 45px;
    height: 30px;
}
.footer_img1{
    width: 20px;
    height: 15px;
}
.footer_img11{
    width: 40px;
    height: 14px;
}
.footer_img12{
    width: 40px;
    height: 35px;
}
.footer_img13{
    width: 55px;
    height: 17px;
}
.footer_img14{
    margin-left: 28px;
    width: 20px;
    height: 15px;
}
.footer_img15{
    margin-left: 10px;
    width: 20px;
    height: 15px;
}
.footer_img31{
    width: 58px;
    height: 32px;
    margin-bottom: 13px;
}
.footer_img32{
    width: 55px;
    height: 18px;
}
#Cartfooter {
    width: 91.99%;
    height: 220px;
    padding: 5% 0 0 10%;
    border: 1px solid rgb(231, 231, 231);
    background-color: #efefef;
    padding: 42px 10px 40px 110px;
    display: grid;
    grid-template-columns: 33.3% 36.3% 30.3%;
    justify-content: center;
}

#Cartfooter #footer1 {
    height: 100%;
    /* background-color: violet; */
    padding: 0 0 0 15%;

}

#Cartfooter #footer11 {
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15%;
    grid-gap: 5px;
}

#footer3 {
    height: 100px;
    /* background-color: rgb(238, 182, 130); */
}
#footer4{
    /* width: 100%; */
    font-size: 13.8px;
    /* padding: 6% 0 7% 100%; */
    /* color: #969696; */
}

#f1 {
    font-size: 13.8px;
    margin: 10px 0 10px 0;
    /* color: #969696; */
}
#give_india{
    display: grid;
    grid-template-columns: 50% 50%;
}
.f11 {
    margin-left: 5px;
    font-size: 12px;

}
.f112{
    width: 100px;
    font-size: 12px;
    /* color: #969696; */
}
#f22{
    /* margin-top: 13px; */
    font-size: 11.8px;
    
}
.f22bold{
    font-weight: 600;
}
#f2 {
    font-size: 13.8px;
    margin: 10px 0 10px 0;
    color: #969696;
}

#f3 {
    font-size: 13.8px;
    margin: 10px 0 10px 0;
    color: #969696;
}

#foot2 {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 6% 60%;
    color: #969696;
}

#foot3 {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 17% 17%;
}
#Cartfooter #footer4{
    margin-top:10px;
    text-align: center;
}