.success{
    text-align: center;

}
.success img{
    width: 100px;
}
.success #btn{
    background-color: green;
    color: white;
    padding: 10px;
    font-size: 16px;
    border-radius: 6px;
    text-decoration: none;
}
.success p {
    color: black;
   
}