.singleproductdiv {
  display: flex;
  margin: 15px 40px;
}
.detailsdiv {
  margin-right: 40px;
  margin-left: 10px;
}

#spandiscount {
  text-decoration: line-through;
}
#bredcrumb{
  font-size: 14px;
  text-decoration: none;
  color: black;
}
